@media (max-width: 768px) {
  .comment__text {
    font-size: 1rem;
  }

  .entry-title {
    top: 250px;
    padding-right: 30px;
    padding-left: 30px;
    font-size: 8vw;

    small {
      font-size: 0.9rem;
    }
  }

  #video-slider {
    padding: 140% 0 0;

    iframe {
      margin-left: -50% !important;
      max-width: 250% !important;
      width: 250% !important;
      height: 100% !important;
    }
  }

  .header__left {
    display: none;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .custom-nav__arrow {
    width: 26px;
    height: 26px;
  }

  .banner-box__content {
    position: relative;
    right: 0 !important;
    bottom: 0;
    left: 0 !important;
    margin: 0 auto;
    max-width: 100%;
  }

  .banner-box__title {
    font-size: 1.6rem;
  }

  .banner-box {
    margin-bottom: 30px;
  }

  .home-rooms-slider {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }

  .option-wrapper ol li,
  .option-wrapper ul li {
    width: 100%;
  }

  .option-wrapper {
    padding: 15px;
  }

  .filter-nav {
    gap: 15px;

    .filter-btn {
      margin: 0;
    }
  }

  .grid-wrapper{
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 110px;
  }

  .restaurant-box__img{
    width: 100%;
    max-width: 100%;
  }

  .home-restaurant__bottom .restaurant-box__img{
    top: auto;
    left: auto;
  }

  .home-restaurant.mt100{
    margin-top: 0;
  }

  .card{
    padding: 30px;
  }

  .card--contact{
    padding-bottom: 30px;
  }
}

.photo-img{
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  background: #fff;
  @extend .bxs;
}

.card-slider{
  padding: 60px;

  .swiper-slide{
    padding: 10px;
  }

  .swiper-slide-shadow{
    background: none !important;
    display: none;
  }
}

.banner-big{
  margin: 30px 0;

  img{
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
}
