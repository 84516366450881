@media (max-width:1400px) {
  .text--big{
    font-size: 1rem;
  }

  .text{
    font-size: .9rem;
  }

  .home-about__left{
    padding-right: 60px;
  }

  .restaurant-box__img{
    top: 0;
  }
}
