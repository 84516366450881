@media (max-width: 992px) {
  .btn-mobil {
    display: inline-block;
  }

  .comments__gallery {
    &.mt150 {
      margin-top: 50px;
    }
  }

  #video-slider {
    padding: 100% 0 0;

    iframe {
      width: 200% !important;
      margin-left: -50%;
      max-width: 200%;
      height: 110% !important;
    }
  }

  .home-about__left {
    width: 100%;
  }

  .home-about__right {
    width: 100%;
    bottom: -15px;
  }

  .home-about__figure {
    width: 100%;
  }

  .section {
    padding: 50px 0;
  }

  .restaurant-box__top {
    padding-right: 0;
  }

  .restaurant-box__img {
    position: relative;
    top: auto;
    right: auto;
  }

  .home-restaurant__bottom .restaurant-box__top {
    padding-left: 0;
  }

  .banner {
    height: 300px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $black-2;
    }
  }

  .room-detail__list{
    width: 100%;
    margin-top: 30px;
    gap:12px;

    li{
      margin: 0;
    }
  }

  .restaurant__img{
    width: 100%;
    height: 40vh;
  }

  .restaurant__info{
    width: 100%;
    padding: 50px 15px;
  }
}
