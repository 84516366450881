@media (max-width: 576px) {
  .header__right{
    width: auto;
    .reservation-btn{
      display: none;
    }
  }

  .header__top{
    height: auto;
  }
}
