.qr-page{

}

.food-menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  &__title {
    display: block;
    font-family: $marcellus;
  }

  &__left {
    width: 240px;
    position: sticky;
    top: 0;

    li {
      display: block;
      margin-bottom: 10px;
    }

    a {
      display: block;
      color: $primary;
      padding: 8px 10px;
      text-decoration: none;
      font-weight: 500;
      font-size: 1.5rem;
      @extend .efekt;

      small {
        display: block;
        margin-top: 2px;
        font-size: 70%;
        color: #848484;
        font-weight: 300;
        @extend .efekt;
      }

      &.active,
      &:hover {
        color: $hover_primary;

        small {
          color: $hover_primary;
          opacity: 0.7;
        }
      }
    }
  }

  &__right {
    width: calc(100% - 240px);
    padding-left: 30px;
    border-left: 1px solid $line;
  }
}

.menu-list {
  li {
    padding: 15px 0;
    display: block;
    border-bottom: 1px solid $line;

    &:last-child {
      border: none;
    }
  }
}

.menu-title {
  @extend .flex;
  font-family: $marcellus;
  font-weight: 500;
  color: $primary;
  font-size: 1.6rem;
  margin-bottom: 10px;

  span {
    &:first-child {
      display: inline-block;
      margin-right: 15px;
    }
  }

  small {
    font-family: $marcellus;
    font-size: 80%;
    color: $primary;
  }
}

.menu-desc {
  font-size: 1rem;
  color: #878787;
}


@media(max-width:992px){

  .food-menu__left a {
    font-size: 1.2rem;
  }

  .food-menu__left {
    width: 180px;
  }

  .food-menu__right {
    width: calc(100% - 180px);
  }

  .menu-title {
    font-size: 1.4rem;
  }
}

@media(max-width:768px){

  .menu-title {
    margin-right: 10px;
  }

  .food-menu__left {
    width: calc(100% + 30px);
    margin-bottom: 15px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 5px;
    top: 0;
    background: #fff;

    .tab-nav {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: 48%;
        margin: 1%;
      }
    }
  }

  .food-menu__right {
    padding-left: 0;
    border: none;
    width: 100%;
  }

  .food-menu__left a {
    font-size: 0.8rem;
    border: 1px solid $primary;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    padding: 2px;

    small {
      width: 100%;
      margin-top: 0;
    }
  }

  .menu-desc {
    font-size: 0.8rem;
  }

  .menu-page{
    margin-top: 0 !important;
  }
}
