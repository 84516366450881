@media (max-width:1200px) {
  #video-slider {
    padding: 65% 0 0;

    iframe {
      width: 140% !important;
      margin-left: -20%;
      max-width: 140%;
      height: 120% !important;
    }
  }

  .entry-title{
    font-size: 6vw;
    top: 30%;
  }

  .header__bottom{
    display: none;
  }

  .header__top{
    border-bottom: 1px solid $white;
  }

  .header__right{
    display: flex;
    justify-content: space-between;
    gap:15px;
    align-items: center;
    width: 210px;
  }

  .header__left{
    width: 210px;
  }

  .btn-mobil{
    display: inline-block !important;
  }

  .title{
    font-size: 2rem;

    &--medium{
      font-size: 18px;
    }
  }

  .restaurant-box{
    width: 100%;

    &__img{
      top: -80px;
    }
  }

  .home-restaurant__figure{
    display: none;
  }

  .home-restaurant__bg{
    width: 85%;
  }
}
