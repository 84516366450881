@import "reset", "colors", "mixin", "global", "hamburgers", "bootstrap", "swiper", "typography", "buttons", "aos";

#__bs_notify__ {
  display: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.mobil-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $black;

  &.active {
    display: block;
  }
}

.mobil {
  display: none;

  &.active {
    display: block;
  }
}

.btn-mobil {
  display: none !important;
}

.hero {
  overflow: hidden;
  max-height: 100vh;
  background: #000;
}

#video-slider {
  position: relative;
  overflow: hidden;
  padding: 55% 0 0;
  height: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 103% !important;
    vertical-align: middle;
  }
}

.header {
  @extend .efekt;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 60;
  width: 100%;
  background: $black-4;
  backdrop-filter:blur(12px);

  &__top {
    padding: 15px 0;
    height: 84px;

    .container-fluid {
      @extend .flex;
    }
  }

  &__left {
    width: 160px;
  }

  &__right {
    width: 160px;
    text-align: right;
  }

  &__bottom {
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
  }

  &--fixed {
    top: -84px;
  }
}

.social {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  &__link {
    @extend .efekt;
    color: #fff;

    &:hover {
      color: $primary;
    }
  }
}

.logo {
  @extend .efekt;
  display: block;
  width: 149px;
}

.reservation-btn {
  @extend .efekt;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  font-weight: 500;
  gap: 12px;

  &:hover {
    color: $primary;
  }
}

.menu {
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  &__item {}

  &__link {
    @extend .efekt;
    display: block;
    padding: 20px 15px;
    color: #fff;
    font-weight: 600;
    font-size: 0.9rem;

    &.active,
    &:hover {
      color: $primary;
    }
  }
}

.entry-title {
  position: absolute;
  top: 40%;
  right: 0;
  left: 0;
  margin: 0 auto;
  max-width: 100%;
  width: 600px;
  color: #fff;
  text-align: center;
  font-size: 4rem;
  font-family: $marcellus;

  small {
    display: block;
    font-weight: 300;
    font-size: 1.2rem;
  }
}

.section {
  position: relative;
  overflow: hidden;
  padding: 100px 0;
}

.text {
  font-size: 1rem;
  line-height: 1.7;

  &--big {
    font-size: 1.1rem;
  }
}

.text-content {
  p {
    display: block;
    margin-bottom: 20px;
  }
}

.home-about {
  .container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__figure {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 80%;
    height: calc(100% - 90px);
    background: #fcf8f4;
  }

  &__left {
    position: relative;
    z-index: 4;
    padding-right: 200px;
    width: 55%;

    .title {
      margin-bottom: 70px;
      width: 400px;
    }
  }

  &__leaf {
    position: absolute;
    top: 90px;
    right: 30px;
  }

  &__text {
    padding-left: 15px;
  }

  &__right {
    position: relative;
    z-index: 4;
    width: 45%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.custom-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $primary;
  gap: 12px;

  &__arrow {
    @extend .efekt;

    svg {
      width: 36px;
      height: 36px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.nav-title {
  @extend .flex;
}

.link {
  @extend .efekt;
  position: relative;
  display: inline-flex;
  align-items: center;
  color: $primary;

  &__circle {
    path {
      @extend .efekt;
    }
  }

  span {
    @extend .efekt;
    display: inline-block;
    margin-left: -25px;
  }

  &:Hover {
    color: $hover_primary;

    span {
      margin-left: -15px;
    }

    .link__circle {
      stroke: $hover_primary;
    }
  }
}

.room {
  position: relative;
  display: block;

  &__img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  &__content {
    @extend .efekt;
    @extend .flex;
    padding: 25px;
    width: 100%;
    background: #fcf8f4;

    small {
      color: #a6a6a6;
      font-size: 0.8rem;
    }
  }

  &__left {}

  &__title {
    display: block;
    margin-bottom: 2px;
    font-weight: 400;
  }

  &:hover {
    .room__content {
      background: darken(#fcf8f4, 10%);
    }
  }
}

.room-slider {
  .swiper-slide {
    @extend .efekt;
    opacity: 0.2;
  }

  .swiper-slide-prev {
    opacity: 1;
  }

  .swiper-slide-next {
    opacity: 1;
  }

  .swiper-slide-active {
    opacity: 1;
  }
}

.banner-boxes {
  .banner-box {
    &:nth-of-type(even) {
      .banner-box__content {
        right: 50px;
        left: auto;
      }
    }
  }
}

.banner-box {
  position: relative;
  margin-bottom: 100px;

  .container {
    position: relative;
  }

  img {
    width: 100%;
  }

  &__content {
    position: absolute;
    bottom: -50px;
    left: 50px;
    padding: 30px;
    max-width: 100%;
    width: 600px;
    background: #FCF8F4;
  }

  &__top {
    @extend .flex;
    margin-bottom: 15px;
    font-size: 2rem;
  }

  &__title {
    color: $primary;
    font-size: 2.5rem;
    font-family: $marcellus;
  }

  &__no {
    font-family: $marcellus;
    opacity: 0.1;
  }
}

.home-restaurant {
  position: relative;

  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%;
    height: 80%;
    object-fit: cover;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__bottom {
    .restaurant-box {
      margin-top: 120px;
      margin-left: auto;

      &__top {
        padding-right: 0;
        padding-left: 55%;
      }

      &__img {
        top: -25%;
        right: auto;
        left: 50px;
      }
    }
  }

  &__figure {
    width: 25%;
  }

  .container {
    position: relative;
    z-index: 4;
  }
}

.restaurant-box {
  position: relative;
  padding: 50px;
  width: 70%;
  background: #FCF8F4;

  &__top {
    position: relative;
    padding-right: 55%;
  }

  &__img {
    position: absolute;
    top: -50%;
    right: 50px;
    max-width: 45%;
  }
}

.footer {
  padding-top: 50px;
  padding-bottom: 15px;
  background: #393024;
  color: #fff;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__logo {
    display: none;
  }

  &__left {}

  &__mid {}

  &__title {
    display: block;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 1.6rem;
    font-family: $marcellus;
  }

  &__menu {
    font-size: 1.1rem;
    column-count: 3;
    column-gap: 50px;

    a {
      @extend .efekt;
      display: inline-block;
      padding: 5px 0;
      font-weight: 300;

      &:hover {
        color: $hover_primary;
      }
    }
  }

  &__right {}

  &__bottom {
    @extend .flex;
    margin-top: 30px;
    padding-top: 15px;
    width: 100%;
    border-top: 1px solid $white;
  }

  &__links {
    a {
      @extend .efekt;
      font-size: 0.9rem;
      opacity: 0.8;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.social {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 14px;

  .bi {
    width: 20px;
    height: 20px;
  }

  &__link {
    @extend .efekt;

    &:hover {
      color: $hover_primary;
    }
  }
}
@media (max-width: 576px) {
  .logo {
    width: 120px;
  }
}
@media (max-width: 768px) {
  .footer__mid {
    margin-top: 30px;
    width: 100%;
  }

  .copyright,
  .footer__links a {
    font-size: 0.8rem;
  }

  .footer__links {
    margin-top: 10px;
  }
}
@media (max-width: 992px) {
  .footer__menu a {
    font-size: 0.9rem;
  }

  .footer__right {
    display: none;
  }
}

.banner {
  @extend .bgcover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 160px;
  height: 500px;
  text-align: center;

  .container {
    position: relative;
    z-index: 4;
  }

  .title {
    color: #fff;
  }

  &--text {
    padding: 0;
    height: 300px;
  }
}

.page {
  padding: 50px 0;
}

.room-detail {
  padding-bottom: 50px;

  &__img {
    margin-bottom: 15px;

    img {
      width: 100%;
    }
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    @extend .flex;

    li {
      margin-left: 15px;
      color: #707070;
      font-size: 0.9rem;

      .bi {
        position: relative;
        top: -2px;
        margin-right: 4px;
      }
    }
  }

  &__title {
    color: $primary;
    font-size: 1.7rem;
    font-family: $marcellus;

    &--big {
      font-size: 2.5rem;
    }
  }

  &__text {
    margin-top: 15px;
    width: 100%;
    color: #575757;
  }

  &--list {
    padding-bottom: 15px;
  }
}

.option-wrapper {
  padding: 30px;
  background: #FBFBFB;

  .title {
    color: $primary;
  }

  ol,
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 30px;
    list-style-type: disc;

    li {
      margin-bottom: 15px;
      width: 48%;
      background-position: center left;
      background-size: 15px;
      background-repeat: no-repeat;
      font-weight: 500;
      font-family: $marcellus;
    }
  }
}

.card {
  @extend .bxs;
  padding: 30px;
  height: 100%;
  border: none;
  border-radius: 20px;
  text-align: center;

  &__icon {
    @extend .bdrs;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    width: 60px;
    height: 60px;
    background: #ecf6fc;
  }

  &__title {
    display: block;
    margin: 15px 0 10px;
    color: $primary;
    font-weight: 400;
    font-size: 1.4rem;
    font-family: $marcellus;
  }

  &__text {
    display: block;
    font-size: 1.1rem;

    a {
      color: $text;
    }
  }

  &--left {
    text-align: left;

    .card__icon {
      margin-left: 0;
    }
  }

  &--240 {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 240px;
  }

  &--contact {
    padding-bottom: 70px;
    background: #f6fbfd;
    box-shadow: none;

    .card__icon {
      background: $primary;
    }
  }
}

.map {
  height: 100%;

  iframe {
    min-height: 350px;
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
}

.contact-form {
  .form-control {
    height: 48px;
    border: 1px solid #dfdfdf;
    box-shadow: none;

    &::placeholder {
      color: #a7a7a7;
    }
  }

  textarea.form-control {
    height: auto;
  }
}

.filter-section {
  padding-top: 30px;
  border-bottom: 1px solid $line;
}

.filter-nav {
  position: relative;
  bottom: -1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.filter-btn {
  @extend .efekt;
  display: inline-block;
  margin-right: 40px;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  color: #9d9d9d;
  text-decoration: none;
  font-weight: 600;
  font-family: $marcellus;

  &.active,
  &:hover {
    border-color: $primary;
    color: $primary;
  }
}

.grid-wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}

.grid-wrapper .wide {
  grid-column: span 2;
}

.grid-wrapper .tall {
  grid-row: span 2;
}

.grid-wrapper .big {
  grid-column: span 2;
  grid-row: span 2;
}

.fresco {
  @extend .efekt;
  display: block;
  height: 100%;
  cursor: zoom-in;

  &:Hover {
    opacity: 0.8;
  }
}

.rest-text {
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 800px;
}

.rest-text.text--bigger {
  color: $primary;
  font-style: italic;
  font-size: 1.6rem;
  font-family: $marcellus;
}

.home-comments {
  padding: 70px 0;
  background: #fff9f3;

  &--product {
    text-align: center;

    .comment-slider {
      margin-right: auto;
      margin-left: auto;
      max-width: 100%;
      width: 600px;
    }

    .custom-button {
      @extend .dikeyAbsolute;
      z-index: 20;
      opacity: 0.6;

      svg {
        width: 46px;
        height: 46px;
      }
    }

    .custom-button--prev {
      left: 0;
    }

    .custom-button--next {
      right: 0;
    }
  }
}

.comments {
  &__small {
    color: $text;
    font-weight: 500;
    font-size: 1.1rem;
  }

  &__gallery {
    @extend .flex;

    img {
      @extend .bdrs;
      width: 100%;
    }

    li {
      width: calc(20% - 15px);
    }

    .fresco {
      @include efekt(top);
      position: relative;
      top: 0;
      cursor: zoom-in;

      &:hover {
        top: -10px;
      }
    }
  }
}

.comment {
  &__stars {
    display: block;

    .bi {
      margin-right: 6px;
      width: 24px;
      height: 24px;
      color: #FFBD4F;
    }
  }

  &__text {
    display: block;
    margin: 30px 0;
    color: #000;
    font-weight: 500;
    font-size: 1.2rem;
  }

  &__name {
    color: $primary;
    font-weight: 500;
    font-size: 1.2rem;
  }
}

.comment-slider {
  position: relative;

  &__nav {
    @extend .flex;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 4;
  }

  .custom-button {
    padding: 0 5px;
    background: #fff9f3;
    color: $text;

    &:hover {
      color: $primary;
    }
  }
}

.owner {
  position: relative;

  &__img {
    position: absolute;
    z-index: 3;
    border: 15px solid #FFFFFF;
    border-radius: 15px;
    filter: drop-shadow(0px 0px 34px rgba(0, 0, 0, 0.14));
  }

  &__big {
    top: 10%;
    left: 24%;
    transform: rotate(-15deg);
  }

  &__small {
    bottom: 4%;
    left: 24%;
    z-index: 4;
    transform: rotate(6deg);
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.owner__big {
  max-width: 70%;
}

.owner__small {
  max-width: 50%;
}

.owner__big {
  max-width: 65%;
}

.owner__small {
  max-width: 45%;
}

.owner__img {
  border: 8px solid #fff;
}

.bar-slider {
  max-width: calc(100% - 12px);
  width: 600px;
  overflow: hidden;
  padding: 30px !important;
}

.restaurant {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--reverse {
      flex-direction: row-reverse;

      .restaurant__img {
        position: relative;
      }
    }
  }

  &__img {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    padding: 70px 50px;
    width: 50%;
    background: #f4f4f4;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    display: block;
    margin-bottom: 15px;
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    color: $primary;
    font-size: 1.7rem;
    font-family: $marcellus;
  }

  .text {
    position: relative;
    z-index: 3;
    margin-bottom: 30px;
  }
}
@import "style1600", "style1400", "style1200", "mobil-nav", "style992", "style768", "style576", "qr";
